import React from 'react';

export default function ProfileMenu()
{
    return (
        <>
            <div className='grid grid-cols-4 mt-20'>
                {/* <div className="block items-center text-sm font-medium text-gray-900 dark:text-white me-3">
                    <div className="flex w-4 justify-center h-4 items-center bg-blue-600 rounded-full me-1.5 flex-shrink-0"></div>
                    xxxxxx
                </div>
                <div className="block items-center text-sm font-medium text-gray-900 dark:text-white me-3">
                    <div className="flex w-4 justify-center h-4 items-center bg-purple-500 rounded-full me-1.5 flex-shrink-0"></div>
                    xxxxxx
                </div>
                <div className="block items-center text-sm font-medium text-gray-900 dark:text-white me-3">
                    <div className="flex w-4 justify-center h-4 items-center bg-indigo-500 rounded-full me-1.5 flex-shrink-0"></div>
                    xxxxxx
                </div>
                <div className="block items-center text-sm font-medium text-gray-900 dark:text-white me-3">
                    <div className="flex w-4 justify-center h-4 items-center bg-teal-500 rounded-full me-1.5 flex-shrink-0"></div>
                    xxxxxx
                </div> */}
            </div>
        </>
    )
}