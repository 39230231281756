import React, { useState, FormEventHandler } from "react";
import TextInput from "../../components/TextInput.tsx";
import { Link } from "react-router-dom";
import Button from "../../components/Button.tsx";
import GuestLayout from '../../layouts/Guest.tsx';
import { useNavigate } from 'react-router-dom';
import CameraComponent from "./register/CameraVerify.tsx";
// import TextSelect from "../../../components/TextSelect.tsx";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth } from '../../firebaseConfig.tsx';
import { addDoc, collection } from "firebase/firestore";
import { database } from "../../firebaseConfig.tsx";

export default function Register()
{
    const navigate = useNavigate();

    //..--* Steps
    const [ step1, setStep1 ] = useState(true);
    const [ step2, setStep2 ] = useState(false);
    const [ step3, setStep3 ] = useState(false);
    const [ step4, setStep4 ] = useState(false);

    //..--* Form Datas
    const [ matricNo, setMatricNo ] = useState('')
    const [ username, setUsername ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    

    //..--* Errors
    const [ matricNoError, setMatricNoError ] = useState();
    const [ usernameError, setUsernameError ] = useState();
    const [ emailAndPasswordError, setEmailAndPasswordError ] = useState();

    //..--*validation
    const validateMatricNo = () => {
        if(matricNo === '')
        {
            setMatricNoError('Matric number is required')
        }else
        {
            setStep1(false); 
            setStep2(true);
        }
    }
    const validateUsername = () => {
        if(username === '')
        {
            setUsernameError('Username is required');
        }else if(username === 'mr.com'){
            setUsernameError('Username is already taken')
        }else
        {
            setStep3(false); 
            setStep4(true);
        }
    }
    const validateEmailAndPassword = () => {
        if(email === '')
        {
            setEmailAndPasswordError('Email is required');
        }else if(password === '')
        {
            setEmailAndPasswordError('Password is required');
        }else if(confirmPassword !== password)
        {
            setEmailAndPasswordError('Passwords does not match');
        }else
        {
            navigate('/verify-email');
        }
    }


    const verifyLogin : FormEventHandler = (e) => {
        e.preventDefault();
        validateEmailAndPassword();

        createUserWithEmailAndPassword(auth, email, password)
        .then(async(userCredential) => {
            const user  = userCredential.user;
            try
            {
                // loginActivitiesResponse
                await addDoc(collection(database, 'login_activities'), {
                    user_id: user.uid,
                    location: null,
                    is_success: 2, //signup
                    created_at: Date.now(),
                })

                // matricNoResponse
                await addDoc(collection(database, 'matric_nos'), {
                    user_id: user.uid,
                    matric_no: matricNo,
                    is_verified: 0,
                    created_at: Date.now(),
                    updated_at: Date.now(),
                    deleted_at: null   
                })

                // usernameResponse
                await addDoc(collection(database, 'usernames'), {
                    user_id: user.uid,
                    name: username,
                    created_at: Date.now(),
                    deleted_at: null   
                })

                // userResponse
                await addDoc(collection(database, 'users'), {
                    user_id: user.uid,
                    created_at: Date.now(),
                    updated_at: Date.now(),
                    deleted_at: null,
                    terminated_at: null,
                })

                sendEmailVerification(user);
                navigate('/verify-email');
            }
            catch(e)
            {
                
            }
        })

        }

    return (
        <GuestLayout>
            <form onSubmit={verifyLogin}>
                { step1 && <div>
                    <div className="grid grid-col gap-4">
                        <small>
                            <b>Step 1:</b> Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos corrupti voluptatem incidunt, saepe eum, magnam assumenda accusamus cupiditate illo
                        </small>
                        <div>
                            <TextInput
                                type = 'text'
                                label = 'Matric Number'
                                value = { matricNo }
                                onChange = { (e) => setMatricNo(e.target.value) }
                            />
                            { matricNoError !== '' && <small className="text-red-600 pl-2">
                                { matricNoError }
                            </small>}
                        </div>

                    </div>
                    <br />
                    <Button
                        text = "Next"
                        onClick={ () => validateMatricNo()}
                    />
                    <p className="mt-2 text-center text-sm text-gray-500">
                        Already have an account?
                        <Link to='/' className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"> Login</Link>
                    </p>
                </div>}

                { step2 &&
                    <div className="grid grid-col gap-4">
                        <CameraComponent/>
                        <Button
                            text = "Next"
                            onClick={ () => { setStep2(false); setStep3(true) }}
                        />
                    </div>
                }

                { step3 && <div>
                    <div className="grid grid-col gap-4">
                        <small>
                            <b>Step 3:</b> Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos corrupti voluptatem incidunt, saepe eum, magnam assumenda accusamus cupiditate illo
                        </small>
                        <div>
                            <TextInput
                                type = 'text'
                                label = 'Username'
                                value = { username }
                                onChange = { (e) => setUsername(e.target.value) }
                            />
                            { usernameError !== '' && <small className="text-red-600 pl-2">
                                { usernameError }
                            </small>}
                        </div>

                    </div>
                    <br />
                    <Button
                        text = "Next"
                        onClick={ () => validateUsername() }
                    />
                </div>}

                {step4 && 
                <div>
                    <div className="grid grid-col gap-4">
                        <small>
                            <b>Step 4:</b> Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos corrupti voluptatem incidunt, saepe eum, magnam assumenda accusamus cupiditate illo
                        </small>
                        <div>
                            <TextInput
                                type = 'email'
                                label = 'Email Address'
                                value = { email }
                                id= "email"
                                onChange = { (e) => setEmail(e.target.value) }
                            />
                        </div>
                        <div>
                            <TextInput
                                type = 'password'
                                label = 'Password'
                                value = { password }
                                id= "password"
                                onChange  = { (e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <TextInput
                                type = 'password'
                                label = 'Confirm Password'
                                value = { confirmPassword }
                                id= "confirm-password"
                                onChange  = { (e) => setConfirmPassword(e.target.value)}
                            />
                            { emailAndPasswordError !== '' && <small className="text-red-600 pl-2">
                                { emailAndPasswordError }
                            </small>}
                        </div>
                    </div>
                    <br />
                    <Button
                        type = "submit"
                        text = "Create Account"
                    />
                </div>}
            </form>
        </GuestLayout>
    )
}