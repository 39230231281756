import React, { PropsWithChildren } from 'react'

export default function HomeNav({ children }:PropsWithChildren)
{
    return (
        <>
            <div className="w-full h-12 fixed top-0 text-gray-800 bg-gray-400/50">
                <div className="text-center text-3xl py-1 px-4" style={{fontFamily: 'Brush Script'}}>
                        <span className="px-2.5">N</span>
                        <span className="px-2.5">a</span>
                        <span className="px-2.5">u</span>
                        <span className="px-2.5">b</span>
                        <span className="px-2.5">i</span>
                        <span className="px-2.5">t</span>
                        <span className="px-2.5">e</span>
                </div>
            </div>
            <div className="mt-8 p-4">
                { children }
            </div>
        </>
    )
}