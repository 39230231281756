import React from "react";
import Authenticated from "../../layouts/Authenticated.tsx";
import HomePost from "./Post.tsx";
import HomeNav from "./Nav.tsx";

export default function Home()
{
    return (
        <Authenticated>
            <HomeNav>
                <HomePost/>
            </HomeNav>
        </Authenticated>
    )
}