import { auth } from '../firebaseConfig.tsx';

function isAuthentificated(){
    return (auth.currentUser ? true : false)    
}

function hasVerifiedEmail(){
    return (auth.currentUser?.emailVerified ? true : false)
}

export {
    isAuthentificated,
    hasVerifiedEmail
}