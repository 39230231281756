import React, { PropsWithChildren } from "react";

export default function GuestLayout({ children }:PropsWithChildren)
{
    return (
        <>
            <div className="flex flex-col justify-center h-screen px-6">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    { children }
                </div>
            </div>
            <div className='absolute bottom-0 left-1/2 transform -translate-x-1/2'>
                <small className='font-bold text-blue-800'>Naubite v2.7</small>
            </div>
        </>
    )
}