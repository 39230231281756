import React, { useState } from 'react'
import Button from '../../components/Button.tsx'
import GuestLayout from '../../layouts/Guest.tsx'
import { hasVerifiedEmail } from '../../hooks/auth.tsx'; 
import { useNavigate } from "react-router-dom";

export default function VerifyEmail()
{
    const navigate = useNavigate()
    const [ emailError, setEmailError ] = useState('');

    const emailVerified = async() => {
        try {
            const verified = await hasVerifiedEmail();
            if(verified)
            {
                navigate('/home');
            }
            else
            {
                setEmailError('Email not verified, please verify and try again');
            }
        }
        catch (e)
        {
            setEmailError('Error: ' + e.code);
        }
    }

    return (
        <GuestLayout>
            <div className="grid grid-col">
                <small>
                    <b>Email Verification:</b> consectetur adipisicing elit. Eos 
                    corrupti voluptatem incidunt, Rerum eos est aut, 
                    repellat excepturi voluptatibus, explicabo iusto tempore, omnis placeat atque
                        soluta quas officia odio minus veniam doloribus? Labore, consequuntur. 
                    <code className="text-blue-500 cursor-pointer"> Resend Verification</code>
                </small>
                { emailError !== '' && <small className="text-red-600">
                    { emailError }
                </small>}
            </div>
            <br />
            <div className="grid">
                <Button
                    text = "I have verified"
                    onClick={ () => emailVerified() }
                />
            </div>
        </GuestLayout>
    )
}