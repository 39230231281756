import React from 'react';

export default function ProfileAdditionalInfo()
{
    return (
        <div className='grid grid-cols-3 mt-20 mx-3'>
            <div className="block justify-center items-center text-sm font-medium text-gray-900 dark:text-white me-3">
                <div className="block text-center ml-2 text-xl">Friends</div>
                <div className="block m-auto bg-gray-100 text-white-800 text-lg font-medium px-1 py-2.5 rounded-full dark:bg-red-900 dark:text-gray-300 text-center mt-2">00</div>
            </div>
            <div className="block justify-center items-center text-sm font-medium text-gray-900 dark:text-white me-3">
                <div className="block text-center ml-2 text-xl">Collegues</div>
                <div className="block m-auto bg-gray-100 text-white-800 text-lg font-medium px-1 py-2.5 rounded-full dark:bg-red-900 dark:text-gray-300 text-center mt-2">00</div>
            </div>
            <div className="block justify-center items-center text-sm font-medium text-gray-900 dark:text-white me-3">
                <div className="block text-center ml-2 text-xl">Devotee</div>
                <div className="block m-auto bg-gray-100 text-white-800 text-lg font-medium px-1 py-2.5 rounded-full dark:bg-red-900 dark:text-gray-300 text-center mt-2">00</div>
            </div>
        </div>
    )
}