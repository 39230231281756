import React, { useRef, useState, useEffect } from 'react';
// import * as faceapi from 'face-api.js';

const CameraComponent: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [isDetecting, setIsDetecting] = useState(false);

  // Initialize face detection models
  const loadModels = async () => {
    setIsDetecting(true);
    // await Promise.all([
    //   faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
    //   faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
    //   faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
    // ]);
    setIsDetecting(false);
  };

  // Access the camera and start streaming
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setIsCameraReady(true);
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  // Take a picture
  const takePicture = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const imageData = canvas.toDataURL('image/png');
        // Do something with the image data (e.g., save it or display it)
        console.log('Captured image data:', imageData);
      }
    }
  };

  // Start the camera and load models when the component mounts
  useEffect(() => {
    startCamera();
    loadModels();
  }, []);

  return (
    <div>
      <small>
          <b>Step 2:</b> Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos corrupti voluptatem incidunt, saepe eum, magnam assumenda accusamus cupiditate illo
      </small>
      <video ref={videoRef} autoPlay muted style={{
        maxWidth: '80%',
        margin: 'auto',
        display: 'block',
        borderRadius: '10%'}} />
      {isCameraReady && !isDetecting && (
        <button onClick={takePicture}
        style={{
          padding: '10px',
          margin: 'auto',
          display: 'block',
          backgroundColor: 'red',
          borderRadius: '100%'
        }}
        ></button>
      )}
      {isDetecting && <p>Detecting faces...</p>}

    </div>
  );
};

export default CameraComponent;
