import React, { useState, useEffect } from 'react'
import { database } from '../../firebaseConfig.tsx';
import { QuerySnapshot, collection, getDocs, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';

export default function ChatAddChat()
{
    const [chatsList, setChatsList] = useState([]);
    const [fetchedUsername, setFetchedUsername] = useState('');

    useEffect(() => {
        async function fetchChats(){
            const usersRef = collection(database, 'users');
            const q = query(usersRef);

            // Fetch data from Firestore
            const querySnapshot: QuerySnapshot = await getDocs(q);

            // Map the query snapshot to an array of user objects
            const fetchedData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setChatsList(fetchedData)
        }
        fetchChats()
    }, [])

    const username = (userId) => {
        async function fetchUsername()
        {
            const usersRef = collection(database, 'usernames');
            const q = query(usersRef, where('user_id', '==', userId));

            // Fetch data from Firestore
            const querySnapshot: QuerySnapshot = await getDocs(q);

            // Map the query snapshot to an array of user objects
            const fetchedData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setFetchedUsername(fetchedData[0]['name'])
        }
        fetchUsername()
        return fetchedUsername
    }

    // const createChat = async(target) => {
    //     await addDoc(collection(database, 'chats'), {
    //         user_id: auth.currentUser?.uid,
    //         target_id: target,
    //         // is_success: 2, //signup
    //         created_at: Date.now(),
    //     })
    // }
    return (
        <>
            <ul className="max-w divide-y divide-gray-200 dark:divide-gray-700 px-2">
                { chatsList.map(list => 
                    <li className="pb-3 sm:pb-4 hover:bg-gray-300">
                        <Link to={'/chat/'+list.target_id}>
                            <div className="flex items-center space-x-4 rtl:space-x-reverse ">
                                <div className="flex-shrink-0">
                                    <img className="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-1.jpg" alt="Neil"/>
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                                        { username(list.target_id) }
                                    </p>
                                    <p className="text-md text-gray-500 truncate dark:text-gray-400">
                                    2 messages
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    <span className="font-semibold bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2 dark:bg-gray-700 dark:text-gray-400">
                                        3
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </li> 
                )}
            </ul>
        </>
    )
}