import React, { useState, FormEventHandler } from 'react';
import TextInput from '../../components/TextInput.tsx';
import Button from '../../components/Button.tsx';
import Logo from '../../logo192.png'
import { Link, useNavigate } from 'react-router-dom';
import GuestLayout from '../../layouts/Guest.tsx';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebaseConfig.tsx';

export default function Login()
{
    const navigate = useNavigate()
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loginError, setLoginError ] = useState('');

    const verifyLogin : FormEventHandler = (e) => {
        e.preventDefault();

        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up 
            // const user = userCredential.user;
            navigate('/home');
            // ...
          })
          .catch((error) => {
            // const errorCode = error.code;
            const errorMessage = error.code;
            setLoginError("Error: " + errorMessage)
            // console.log(user);
            // ..
          });
        // try {
        //     await auth.signInWithEmailAndPassword(data.email, data.password);
        //     alert("Completed")
        // } catch (error) {
        //     alert("Error");
        // }   
    }

    return (
        <GuestLayout>
            {/* <div className="flex  "> */}
                <div>
                    <img className="mx-auto h-20 w-20" src={ Logo } alt="Your Company" />
                    <h2 className="text-center text-lg leading-9 tracking-tight text-gray-900">Login</h2>
                </div>

                <div className="mt-4">
                    <form onSubmit={verifyLogin}>
                        <div className="space-y-1">
                            <div>
                                <TextInput
                                    type = 'email'
                                    label = 'Email Address'
                                    value = { email }
                                    id= "email"
                                    onChange = { (e) => setEmail(e.target.value) }
                                />
                            </div>
                            <div>
                                <div className="mb-1 text-right text-sm">
                                    <Link to='/forget-password' className="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</Link>
                                </div>
                                <TextInput
                                    type = 'password'
                                    label = 'Password'
                                    value = { password }
                                    id= "password"
                                    onChange  = { (e) => setPassword(e.target.value)}
                                />
                            </div>
                            { loginError !== '' && <small className="text-red-600 pl-2">
                                { loginError }
                            </small>}
                        </div>
                        <div className='mt-2'>
                            <Button
                                text = "Login"
                                type = "submit"
                            />
                        </div>
                    </form>

                    <p className="mt-2 text-center text-sm text-gray-500">
                        Don't have an account?
                        <Link to='/register' className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"> Sign Up</Link>
                    </p>
                </div>
            {/* </div>    */}
        </GuestLayout>
    )
}