import React from 'react';
import Authenticated from '../../layouts/Authenticated.tsx';
import ProfileUserInfo from './config/UserInfo.tsx';
import ProfileMenu from './config/Menu.tsx';
import ProfileAdditionalInfo from './config/AdditionalInfo.tsx';

export default function Profile()
{
    return (
        <Authenticated>
            <ProfileUserInfo/>
            <ProfileAdditionalInfo/>
            <ProfileMenu/>
        </Authenticated>
    )
}