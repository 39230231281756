import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/auth/Login.tsx";
import Register from "../pages/auth/Register.tsx";
import Home from "../pages/home/Index.tsx";
import Profile from "../pages/profile/Index.tsx";
import VerifyEmail from "../pages/auth/VerifyEmail.tsx";
import ChatList from "../pages/chat/List.tsx";
import ChatRoom from "../pages/chat/Room.tsx";
//import { auth } from "../firebaseConfig.tsx";
import ChatAddChat from "../pages/chat/AddChat.tsx";


export default function RouteList()
{

    return (
        <Routes>
            {/* Auth Routing */}
            {/* <Route path='/' element={<Landing/>} /> */}
            <Route index path='/' element={<Login/>} />
            <Route path='/register' element={<Register/>} />
            <Route path='/verify-email' element={<VerifyEmail/>} />

            <Route path="/home" element={<Home/>} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/chat" element={<ChatList/>} />
            <Route path="/chat-add" element={<ChatAddChat/>} />
            <Route path="/chat/:id/:userId" element={<ChatRoom/>} />
        </Routes>
    )
}