// import { useEffect } from 'react';
import { QuerySnapshot, collection, getDocs, query, where } from 'firebase/firestore';
import { auth, database } from '../../firebaseConfig.tsx';


const MatricNo = async() => {
    const usersRef = collection(database, 'matric_nos');
    const q = query(usersRef, where('user_id', '==', auth.currentUser?.uid));

    // Fetch data from Firestore
    const querySnapshot: QuerySnapshot = await getDocs(q);

    // Map the query snapshot to an array of user objects
    const fetchedData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));

   return fetchedData[0]['matric_no'];
}

const Username = async() => {
    const usersRef = collection(database, 'usernames');
    const q = query(usersRef, where('user_id', '==', auth.currentUser?.uid));

    // Fetch data from Firestore
    const querySnapshot: QuerySnapshot = await getDocs(q);

    // Map the query snapshot to an array of user objects
    const fetchedData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));

   return fetchedData[0]['name'];
}

export { 
    MatricNo, 
    Username, 
}