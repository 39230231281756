import React from 'react';
// import logo from './logo.svg';
import './App.css';
// import Login from './pages/auth/Login';
import RouteList from './routes/Route.tsx';
// import { Link } from 'react-router-dom';

function App() {
  return (
      <>
        <RouteList/>    
      </>
  );
}

export default App;
