import React, { useState } from 'react'
import Authenticated from '../../layouts/Authenticated.tsx'
import { Link } from 'react-router-dom'
import { QuerySnapshot, collection, getDocs, query, where } from 'firebase/firestore';
import { auth, database } from '../../firebaseConfig.tsx';

export default function ChatList()
{
    const [addMenu, setAddMenu ] = useState(false);
    const [chatsList, setChatsList] = useState([]);
    const [usernames, setUsernames] = useState([]);

    const AddMenuAction = () => {
        if(addMenu === false)
        {
            setAddMenu(true)
        }else
        {
            setAddMenu(false)
        }
    }

    async function fetchChats(){
        const usersRef = collection(database, 'chats');
        const q = query(usersRef, where('user_id', '==', auth.currentUser?.uid));

        // Fetch data from Firestore
        const querySnapshot: QuerySnapshot = await getDocs(q);

        // Map the query snapshot to an array of user objects
        const fetchedData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setChatsList(fetchedData)
    }

    async function username()
    {
        const usersRef = collection(database, 'usernames');
        const q = query(usersRef);

        // Fetch data from Firestore
        const querySnapshot: QuerySnapshot = await getDocs(q);

        // Map the query snapshot to an array of user objects
        const fetchedData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setUsernames(fetchedData)
    }

    // useEffect(() => {
        fetchChats()
        username()

        var allChats = []
        chatsList.forEach(chatlist =>  {
            usernames.forEach(username => {
                if(chatlist.target_id === username.user_id)
                {
                    allChats.push({
                        id: chatlist.id,
                        target_id: chatlist.target_id,
                        username: username.name
                    })
                }
            })
        })
    // }, [])


    
    return (
        <Authenticated>
            <div className="flex gap-3 mt-2 overflow-auto w-auto overscroll-none pb-2">
                <div className="relative">
                    <img className="w-20 h-20 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
                    <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                </div>
                <div className="relative">
                    <img className="w-20 h-20 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
                    <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                </div>
                <div className="relative">
                    <img className="w-20 h-20 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
                    <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                </div>
                <div className="relative">
                    <img className="w-20 h-20 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
                    <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                </div>
                <div className="relative">
                    <img className="w-20 h-20 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
                    <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                </div>
                <div className="relative">
                    <img className="w-20 h-20 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
                    <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                </div>
                <div className="relative">
                    <img className="w-20 h-20 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
                    <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                </div>
                <div className="relative">
                    <img className="w-20 h-20 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt=""/>
                    <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                </div>
            </div>
            <br />
            <ul className="max-w divide-y divide-gray-200 dark:divide-gray-700 px-2">
                { allChats.map(list => 
                    <li className="pb-3 sm:pb-4 hover:bg-gray-300">
                        <Link to={'/chat/' + list.id + '/' + list.target_id}>
                            <div className="flex items-center space-x-4 rtl:space-x-reverse ">
                                <div className="flex-shrink-0">
                                    <img className="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-1.jpg" alt="Neil"/>
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="text-lg font-medium text-gray-900 truncate dark:text-white">
                                        { list.username ? list.username : <div role="status" className="max-w-sm animate-pulse"><div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div></div> }
                                    </p>
                                    <p className="text-sm -mt-1.5 text-gray-500 truncate dark:text-gray-400">
                                        2 messages
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    <span className="font-semibold bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2 dark:bg-gray-700 dark:text-gray-400">
                                        3
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </li> 
                )}
            </ul>
            <div data-dial-init className="fixed end-6 bottom-12 group">
                { addMenu && <div className="flex flex-col items-center mb-4 space-y-2">
                    <Link to='/chat-add'>
                        <button type="button" data-tooltip-target="tooltip-share" data-tooltip-placement="left" className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                <path d="M14.419 10.581a3.564 3.564 0 0 0-2.574 1.1l-4.756-2.49a3.54 3.54 0 0 0 .072-.71 3.55 3.55 0 0 0-.043-.428L11.67 6.1a3.56 3.56 0 1 0-.831-2.265c.006.143.02.286.043.428L6.33 6.218a3.573 3.573 0 1 0-.175 4.743l4.756 2.491a3.58 3.58 0 1 0 3.508-2.871Z"/>
                            </svg>
                            <span className="sr-only">Share</span>
                        </button>
                        <div id="tooltip-share" role="tooltip" className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            Share
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                    </Link>
                    <button type="button" data-tooltip-target="tooltip-print" data-tooltip-placement="left" className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M5 20h10a1 1 0 0 0 1-1v-5H4v5a1 1 0 0 0 1 1Z"/>
                            <path d="M18 7H2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2v-3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2Zm-1-2V2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v3h14Z"/>
                        </svg>
                        <span className="sr-only">Print</span>
                    </button>
                    <div id="tooltip-print" role="tooltip" className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Print
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button type="button" data-tooltip-target="tooltip-download" data-tooltip-placement="left" className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 shadow-sm dark:hover:text-white dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z"/>
                            <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"/>
                        </svg>
                        <span className="sr-only">Download</span>
                    </button>
                    <div id="tooltip-download" role="tooltip" className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Download
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <button type="button" data-tooltip-target="tooltip-copy" data-tooltip-placement="left" className="flex justify-center items-center w-[52px] h-[52px] text-gray-500 hover:text-gray-900 bg-white rounded-full border border-gray-200 dark:border-gray-600 dark:hover:text-white shadow-sm dark:text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 focus:outline-none dark:focus:ring-gray-400">
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M5 9V4.13a2.96 2.96 0 0 0-1.293.749L.879 7.707A2.96 2.96 0 0 0 .13 9H5Zm11.066-9H9.829a2.98 2.98 0 0 0-2.122.879L7 1.584A.987.987 0 0 0 6.766 2h4.3A3.972 3.972 0 0 1 15 6v10h1.066A1.97 1.97 0 0 0 18 14V2a1.97 1.97 0 0 0-1.934-2Z"/>
                            <path d="M11.066 4H7v5a2 2 0 0 1-2 2H0v7a1.969 1.969 0 0 0 1.933 2h9.133A1.97 1.97 0 0 0 13 18V6a1.97 1.97 0 0 0-1.934-2Z"/>
                        </svg>
                        <span className="sr-only">Copy</span>
                    </button>
                    <div id="tooltip-copy" role="tooltip" className="absolute z-10 invisible inline-block w-auto px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        Copy
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                </div> }
                <button onClick={() => AddMenuAction()} type="button" data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default" aria-expanded="false" className="flex items-center justify-center text-white bg-gray-500 rounded-full w-14 h-14 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                    <svg className="w-5 h-5 transition-transform group-hover:rotate-45" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
                    </svg>
                    <span className="sr-only">Open actions menu</span>
                </button>
            </div>

        </Authenticated>
    )
}